
import React from 'react';
import { ColorButtonPalette } from './ColorButtonPalette';
import { IColorButtonPaletteParams } from './IColorButtonPaletteParams';
import { IWidthButtonParams } from './IWidthButtonParams';
import { WidthButton } from './WidthButton';
import './Left.css';

interface ILeftPanelParams extends IWidthButtonParams, IColorButtonPaletteParams {
}

function LeftPanel(params: ILeftPanelParams) {
    return <div className="left-panel">
        <ColorButtonPalette onColorChange={params.onColorChange} />
        <WidthButton onWidthChanged={params.onWidthChanged} />
    </div>
}



export default LeftPanel;
