
import React from 'react';
import { Stripe } from './Stripe';
import { Palette } from './Palette';
import { PlaceOfSignature } from './PlaceOfSignature';

interface IBackgroundParams {
    figure: any;
    stripesWidth: number;
    palette: Palette;
    svg: {
        width: number;
        height: number;
        radius: number;
    },
    class?: string;
}

function Background(params: IBackgroundParams) {

    const size: number = 100;
    const sizeOfOne: number = params.stripesWidth;
    const count: number = size / sizeOfOne;
    let index = 0;

    let array = [];
    params.palette.resetCounter()
    while (index < count) {
        array.push(new Stripe(params.palette.next(), index * sizeOfOne, sizeOfOne))
        index++;
    }

    const rects = array.map(stripe => <rect key={Math.random()} fill={stripe.fill} height="100%" width={`${stripe.width}%`} x={`${stripe.xPos}%`}></rect>)

    return <svg height={params.svg.height} width={params.svg.width} className={params.class}>
        <defs>
            <clipPath id="cut-off-circle">
                <circle id="figure" r={params.svg.radius} cx={params.svg.radius} cy={params.svg.radius} />
            </clipPath>
        </defs>
        <g clipPath="url(#cut-off-circle)">
            {rects}
            {params.figure}
        </g>

        <PlaceOfSignature top={0} left={0} fill="white" stroke="black" displayText="CRUZ-DIEZ interpreted by:" />
    </svg>;
}


export default Background;
