import './Header.css';
import { IHeaderParams } from './IHeaderParams';

function Header(params: IHeaderParams) {
    const text = "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id ab magnam recusandae enim est nemo perspiciatis natus eius porro quibusdam suscipit, cum beatae qui ea illum ratione, unde ducimus voluptatum! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id ab magnam recusandae enim est nemo perspiciatis natus eius porro quibusdam suscipit, cum beatae qui ea illum ratione, unde ducimus voluptatum!";
    
    return <header className="header">
        <p className="header__text">
            {(text.length > params.textMaxLength ? `${text.substring(0, params.textMaxLength)} (...)` : text)}
        </p>
    </header>
}

export default Header;