import { StripeWidthList } from '../components/StripeWidthList';
import { ShapePosition } from './IQueryParamsHelperParams';

const getEscapedSearch = (): string => {
    var escapedSearch = '';
    for (let i = 0; i < window.location.search.length; i++) {
        const element = window.location.search[i];
        escapedSearch += element.replace("_", "&").replace(":", "=");
    }
    return escapedSearch;
}

const getColorsArray = (queryParams: any, deafultColors: string[]): string[] => {
    let colorArr: string[] = [];
    for (let i = 1; i <= deafultColors.length; i++) {
        colorArr.push(`${queryParams.get(`c${i}`) === null ? deafultColors[i - 1] : queryParams.get(`c${i}`)}`);
    }

    return colorArr;
}

const getStripesWidth = (queryParams: any, stripeWidthList: StripeWidthList): number => {
    let presettedStripesWidth: number = 0;

    if (queryParams.get('stripesWidth') !== null) {
        const floatQueryParam: number = Number(queryParams.get('stripesWidth'));

        do {
            presettedStripesWidth = stripeWidthList.next();
        } while (stripeWidthList.widths.includes(floatQueryParam) && presettedStripesWidth !== floatQueryParam);
    } else {
        presettedStripesWidth = stripeWidthList.next();
    }

    return presettedStripesWidth;
}

const getShapePosition = (queryParams: any): ShapePosition => {
    let presettedLeft: number = 170;
    let presettedTop: number = 125;

    if (queryParams.get('left') !== null) {
        const left: number = Number(queryParams.get('left'));

        if (left > -175 && left < 520) {
            presettedLeft = left;
        }
    }

    if (queryParams.get('top') !== null) {
        const top: number = Number(queryParams.get('top'));

        if (top > -220 && top < 476) {
            presettedTop = top;
        }
    }

    return {
        left: presettedLeft,
        top: presettedTop
    };
}

export { getColorsArray, getStripesWidth, getShapePosition, getEscapedSearch };