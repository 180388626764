import React from 'react';

interface IPlaceOfSignatureParams {
    left: number;
    top: number;
    fill: string;
    stroke: string;
    displayText: string;
}

export function PlaceOfSignature(params: IPlaceOfSignatureParams) {
    return <g style={{ transform: `translate(${params.left}px, ${params.top}px)` }}>
        <path id="text-curve" d="M 10 240 Q 40 480 240 480 L 240 460 Q 60 450 30 240 L 10 240" fill="transparent" />
        <text width="500" dx="8%">
            <textPath href="#text-curve" textLength="65%" fontSize="24px">{ params.displayText }</textPath>
        </text>
        <path stroke={params.stroke} d="M 250 490 Q 390 490 460 370 L 430 360 Q 380 460 250 460 L 250 490" fill={params.fill} />
    </g>;
}