import { IFooterParams } from "./IFooterParams";
import { FooterIcon } from "./FooterIcon";
import './Footer.css';
import { IConfig, loadConfig } from "../../utils/IConfig";

export function Footer(params: IFooterParams) {

    var createPrintUrl = (print: boolean): string => {
        const config: IConfig = loadConfig();
        let colorsString = "";
        params.colors.forEach((color, index) => colorsString += `_c${index + 1}:${color}`);
        var urlForPrint = `${config.selfUrlForPrintService}?print=${print}_stripesWidth:${params.stripesWidth}${colorsString}_left:${params.shapePosition.left}_top:${params.shapePosition.top}`
        return print ? `${config.printServiceUrl}?url=${urlForPrint}` : urlForPrint;
    }
    var download = () => {
        var link = document.createElement('a');
        link.href = createPrintUrl(true);

        link.download = 'file.pdf';
        link.dispatchEvent(new MouseEvent('click'));
    }

    var renderQr = () => {
        var link = document.createElement('a');

        link.href = `/?qrcode=${createPrintUrl(false)}`

        link.dispatchEvent(new MouseEvent('click'));
    }

    return <footer className="footer">
        <div className="footer__icon-wrapper">
            <FooterIcon class="footer__icon" alt="Stripes icon" imagePath="assets/img/stripes-icon.png" title="Test title" onClickAction={() => alert("Empty action")} />
            <FooterIcon class="footer__icon" alt="Crossed out stripes icon" imagePath="assets/img/stripes-cross-icon.png" onClickAction={() => alert("Empty action")} />
        </div>

        <div className="footer__icon-wrapper">
            <FooterIcon class="footer__icon" alt="Arrow green icon" imagePath="assets/img/arrow-green.png" onClickAction={renderQr} />
            <FooterIcon class="footer__icon" alt="Arrow red icon" imagePath="assets/img/arrow-red.png" onClickAction={download} />
        </div>
    </footer>
}

export default Footer;