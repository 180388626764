import React, { useState } from 'react';
import './App.css';
import LeftPanel from './components/Left/LeftPanel';
import Header from './components/Top/Header';
import Footer from './components/Bottom/Footer';
import { Palette } from './components/Palette';
import { StripeWidthList } from './components/StripeWidthList';
import { Vizualization } from './components/Vizualization';
import { getColorsArray, getStripesWidth, getShapePosition, getEscapedSearch } from './utils/queryParamsHelper';
import { ShapePosition } from './utils/IQueryParamsHelperParams';
import QrCode from './components/QrCode';

const stripeWidthList = new StripeWidthList(2);

const queryParams = new URLSearchParams(getEscapedSearch());
const print: boolean = (queryParams.get('print') === 'true' ? true : false);
const qrCode: boolean = (queryParams.get('qrcode') === null ? false : true);

const presettedColorArr: string[] = getColorsArray(queryParams, ["blue", "yellow", "red", "blue", "yellow", "red"]);
const presettedStripesWidth: number = getStripesWidth(queryParams, stripeWidthList);
const presettedShapePostion: ShapePosition = getShapePosition(queryParams);

function App() {
  const [state, setState] = useState({ shapePosition: presettedShapePostion, stripesWidth: presettedStripesWidth, colors: [...presettedColorArr], selectedStripe: 0 });

  if (qrCode)
    return <QrCode />
  if (print) {
    return (
      <div className="App">
        <div className="App-header" style={{ paddingTop: 5 }}>
          <div className="App-content">
            <div className="App-vizualization-wrapper">
              <Vizualization
                palette={new Palette([state.colors[0], state.colors[1], state.colors[2]])}
                onStripeChanged={(i) => { setState(pv => ({ ...pv, selectedStripe: i })) }}
                stripesWidth={state.stripesWidth}
                selectedStripe={state.selectedStripe}
                print={print} shapePosition={state.shapePosition}
                onShapeMove={(position) => { setState(pv => ({ ...pv, shapePosition: position })) }}
              />
              <div style={{ margin: 10 }} />
              <Vizualization
                palette={new Palette([state.colors[3], state.colors[4], state.colors[5]])}
                onStripeChanged={(i) => { setState(pv => ({ ...pv, selectedStripe: i + 3 })) }}
                stripesWidth={state.stripesWidth}
                selectedStripe={state.selectedStripe - 3}
                print={print} shapePosition={state.shapePosition}
                onShapeMove={(position) => { setState(pv => ({ ...pv, shapePosition: position })) }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="App">
        <div className="App-header" style={{ padding: 20 }}>
          <Header textMaxLength={300} />

          <div className="App-content">
            <LeftPanel
              /* should we change width ? should we for both?*/
              onWidthChanged={() => setState(pv => ({ ...pv, stripesWidth: stripeWidthList.next() }))}
              onColorChange={(c) => setState(pv => {
                const colors = pv.colors;
                colors[pv.selectedStripe] = c;
                return ({ ...pv, colors: colors })
              })}
            />

            <div className="App-vizualization-wrapper">
              <Vizualization
                palette={new Palette([state.colors[0], state.colors[1], state.colors[2]])}
                onStripeChanged={(i) => { setState(pv => ({ ...pv, selectedStripe: i })) }}
                stripesWidth={state.stripesWidth}
                selectedStripe={state.selectedStripe}
                print={print} shapePosition={state.shapePosition}
                onShapeMove={(position) => { setState(pv => ({ ...pv, shapePosition: position })) }}
              />
              <div style={{ margin: 20 }} />
              <Vizualization
                palette={new Palette([state.colors[3], state.colors[4], state.colors[5]])}
                onStripeChanged={(i) => { setState(pv => ({ ...pv, selectedStripe: i + 3 })) }}
                stripesWidth={state.stripesWidth}
                selectedStripe={state.selectedStripe - 3}
                print={print} shapePosition={state.shapePosition}
                onShapeMove={(position) => { setState(pv => ({ ...pv, shapePosition: position })) }}
              />
            </div>
          </div>

          <Footer stripesWidth={state.stripesWidth} colors={state.colors} shapePosition={state.shapePosition} />
        </div>
      </div>
    );
  }
}

export default App;




