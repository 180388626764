import React from 'react';
import { IStripeButtonParams } from './IStripeButtonParams';
import './Right.css';
import './StripeButton.css';

export function StripeButton(params: IStripeButtonParams) {
  return <div className="stripe-container">
    <div className={'stripe-button ' + (params.selectedStripe === 0 ? 'stripe-button--active' : '')} style={{ background: params.palette.colors[0] }} onClick={() => { params.onStripeChanged(0); }}></div>
    <div className={'stripe-button ' + (params.selectedStripe === 1 ? 'stripe-button--active' : '')} style={{ background: params.palette.colors[1] }} onClick={() => { params.onStripeChanged(1); }}></div>
    <div className={'stripe-button ' + (params.selectedStripe === 2 ? 'stripe-button--active' : '')} style={{ background: params.palette.colors[2] }} onClick={() => { params.onStripeChanged(2); }}></div>
  </div>
}
