

export class StripeWidthList {
    widths: number[] = [0.2, 0.5, 0.8, 1, 1.5];
    counter: number = 0;

    constructor(counter: number = 0) {
        this.counter = counter;
    }

    current(): number {
        return this.widths[this.counter % this.widths.length];
    }

    next(): number {
        return this.widths[this.counter++ % this.widths.length];
    }
}
