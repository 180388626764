

const loadConfig = (): IConfig => {
    let config: IConfig = (window as any).config;
    return config
}


export interface IConfig {
    printServiceUrl: string,
    selfUrlForPrintService: string
}

export { loadConfig }