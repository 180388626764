import React, { useState } from 'react';
import Background from './Background';
import useKeypress from 'react-use-keypress';
import { Figure } from './Figure';
import { StripeButton } from './Right/StripeButton';
import { IVizualizationParams } from './IVizualizationParams';

export function Vizualization(params: IVizualizationParams) {
  const step: number = 1.54;
  const [state, setState] = useState({ left: params.shapePosition.left, top: params.shapePosition.top });

  /*  useKeypress is for testing - please remove it after developing*/
  useKeypress(['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'], (event: any) => {
    if (event.key === 'ArrowLeft') {
      setState(pv => ({ ...pv, left: pv.left -= step }));
    } else if (event.key === 'ArrowRight') {
      setState(pv => ({ ...pv, left: pv.left += step }));
    } else if (event.key === 'ArrowUp') {
      setState(pv => ({ ...pv, top: pv.top -= step }));
    } else if (event.key === 'ArrowDown') {
      setState(pv => ({ ...pv, top: pv.top += step }));
    }

    params.onShapeMove({left: Math.floor(state.left), top: Math.floor(state.top)});
  });

  if(params.print) {
    return <div className="d-flex">
      <Background class="svg-print" palette={params.palette} stripesWidth={params.stripesWidth} figure={<Figure left={state.left} top={state.top} stripesWidth={params.stripesWidth} />} svg={{width: 500, height: 500, radius: 250}} />
    </div>;
  } else {
    return <div className="d-flex">
      <Background palette={params.palette} stripesWidth={params.stripesWidth} figure={<Figure left={state.left} top={state.top} stripesWidth={params.stripesWidth} />} svg={{width: 500, height: 500, radius: 250}} />
      <StripeButton palette={params.palette} selectedStripe={params.selectedStripe} onStripeChanged={(i) => {
        params.onStripeChanged(i);
      }} />
    </div>;
  }
}
