import React from 'react';
import { Color } from '../Color';
import { IColorButtonPaletteParams } from './IColorButtonPaletteParams';
import './ColorButtonPalette.css';

export function ColorButtonPalette(params: IColorButtonPaletteParams) {
    const colorList: Color[] = [new Color("black", "black"),    new Color("silver", "silver"),
                                new Color("gray", "gray"),      new Color("white", "white"),
                                new Color("maroon", "maroon"),  new Color("red", "red"),
                                new Color("purple", "purple"),  new Color("fuchsia", "fuchsia"),
                                new Color("green", "green"),    new Color("lime", "lime"),
                                new Color("olive", "olive"),    new Color("yellow", "yellow"),
                                new Color("navy", "navy"),      new Color("blue", "blue"),
                                new Color("teal", "teal"),      new Color("aqua", "aqua"),
                            ];

    var colorDivs = colorList.map(x => 
        <div key={x.name} onClick={() => { params.onColorChange(x.value); }} className="color-button-palette__button" style={{ background: x.value }} title={x.name}></div>
    );
    
    return <>
        {colorDivs}
    </>;
}
