export class Palette {
    colors: string[] = ["blue", "yellow", "red"];
    counter: number = 0;

    constructor(colors: string[]) {
        this.colors = colors;
        this.counter = 0
    }

    resetCounter() {
        this.counter = 0
    }

    next(): string {
        return this.colors[this.counter++ % this.colors.length];
    }
}



