import React from 'react';
import { Stripe } from './Stripe';

interface IFigureParams {
    top: number;
    left: number;
    stripesWidth: number;
}

export function Figure(params: IFigureParams) {

    const size: number = 100;
    const sizeOfOne: number = params.stripesWidth;
    const count: number = size / sizeOfOne;
    let index = 0;

    let array = [];
    while (index < count) {
        array.push(new Stripe("black", index * sizeOfOne + 0.3, sizeOfOne))
        index++;
        array.push(new Stripe("black", index * sizeOfOne + 0.3, sizeOfOne))
        index += 2;
    }

    const rects = array.map(stripe => <rect key={Math.random()} fill={stripe.fill} height="100%" width={`${stripe.width}%`} x={`${stripe.xPos}%`}></rect>)

    return <g fillRule="evenodd" fill="black" style={{ transform: `rotate(5deg) translate(${params.left}px, ${params.top}px)` }}>
        <defs>
            <mask id="stripes">
                <rect width="100%" height="100%" fill="white" />
                {rects}
            </mask>
        </defs>

        <circle id="figure" r="100" cx="100" cy="100" mask="url(#stripes)" />
    </g>;
}
