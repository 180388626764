import React from "react";
import QRCode from "react-qr-code";
// import { IConfig, loadConfig } from "../utils/IConfig";
import './QrCode.css';

const queryParams = new URLSearchParams(window.location.search);

function QrCode() {
    var params = queryParams.get('qrcode') || "";
    // const config: IConfig = loadConfig();
    return <div className="qr__center">
        <QRCode value={params} level="H" size={512} />
        {/* <a href={`${config.printServiceUrl}?url=${params}`}>SIEMA</a> */}
    </div>
}

export default QrCode;
